<template>
  <div class="box-container container">
    <div ref="contentToPDF">
      <div class="box-header font-style mb-3">
        <div class="d-flex justify-content-between">
          <div class="d-flex flex-column align-items-center">
            <div>{{ org.aic }}</div>
            <div>{{ org.name }}</div>
          </div>
          <div class="d-flex flex-column align-items-center">
            <div>Danh sách bệnh nhân mổ</div>
            <div>{{ date }}</div>
          </div>
        </div>
      </div>
      <table class="mb-3">
        <tr class="font-style">
          <th width="50px">STT</th>
          <th width="10%">Mã điều trị</th>
          <th width="10%">Họ và tên</th>
          <th width="30px">Tuổi</th>
          <th width="10%">Ngày vào viện</th>
          <th width="10%">Chẩn đoán</th>
          <th width="10%">Cách thức mổ</th>
          <th width="10%">Kíp mổ</th>
          <th width="10%">Thông qua</th>
          <th>Lý do</th>
        </tr>
        <tr
          class="table-row"
          v-for="(item, i) in tableData"
          :key="i+'danhsachmo'"
        >
          <td>{{i+1}}</td>
          <td>{{ item.code }}</td>
          <td>{{ item.patient_name }}</td>
          <td>{{ item.age }}</td>
          <td>{{ item.admission_date }}</td>
          <td>{{ item.patient_diagnostic }}</td>
          <td v-html="item.surgery_method" />
          <td v-html="item.surgical_team" />
          <td>{{ item.status }}</td>
          <td></td>
        </tr>
      </table>
      <div class="box-footer">
        <div>
          <div class="font-style">Lãnh đạo khoa</div>
          <div class="sign-text">{{input.input1}}</div>
          <input
            class="sign-input"
            v-model="input.input1"
            type="text"
          >
        </div>
        <div>
          <div class="font-style">Đại diện tiểu ban</div>
          <div class="sign-text">{{input.input2}}</div>
          <input
            class="sign-input"
            v-model="input.input2"
            type="text"
          >
        </div>
        <div>
          <div class="font-style">Phòng KHTH</div>
          <div class="sign-text">{{input.input3}}</div>
          <input
            class="sign-input"
            v-model="input.input3"
            type="text"
          >
        </div>
        <div>
          <div class="font-style">Chủ trì thông qua mổ</div>
          <div class="sign-text">{{input.input4}}</div>
          <input
            class="sign-input"
            v-model="input.input4"
            type="text"
          >
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end my-5 d-print-none">
      <div class="d-flex">
        <select
          v-model="clinicId"
          class="form-select radius-16 border bg-white fs-16 fw-500 txt-grey-900"
        >
          <option
            v-for="(item, index) in workspaces"
            :value="item.id"
            :key="index+'danhsach'"
          >{{ item.name }}</option>
        </select>
        <button
          class="btn bg-pri bd-pri text-white ml-3"
          @click="print"
        >In danh sách</button>
        <button
          class="btn bg-pri bd-pri text-white ml-3"
          @click="back"
        >Quay lại</button>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import mdtUtils from '../../utils/mdtUtils'
import moment from 'moment'
import { TQM_NOT_PASS, TQM_PASS, TQM_TYPE } from '../../utils/constants'

export default {
  name: 'OrganizationCasePrinter',
  data () {
    return {
      input: {},
      mdtCases: [],
      mdtSession: {},
      clinicId: null
    }
  },
  computed: {
    DoctorRequest () {
      return this.$rf.getRequest('DoctorRequest')
    },
    tableData () {
      return _.chain(this.mdtCases)
        .filter(e => e.clinic_id === this.clinicId && e.type === TQM_TYPE && [TQM_PASS, TQM_NOT_PASS].includes(e.status))
        .map(item => {
          const admission_date = moment(item.admission_date)
          return {
            ...item,
            age: mdtUtils.getNumberAges(item.patient_birthday),
            admission_date: admission_date.isValid() ? admission_date.format('DD/MM/YYYY') : '',
            status: mdtUtils.getStatusPublicMdtCase(item.status)?.text
          }
        }).value()
    },
    date () {
      const date = moment(this.mdtSession.date)
      return date.isValid() ? `${date.format('dddd')} ngày ${date.format('DD.MM.YYYY')}` : date
    },
    org () {
      const org = this.$globalOrg
      return {
        name: org?.name,
        aic: org?.authority_in_charge
      }
    },
    workspaces () {
      return _.chain(this.mdtCases)
        .map('clinic')
        .unionBy('id')
        .value()
    }
  },
  methods: {
    async fetchData () {
      const mdtSessionId = this.$route.params.id
      const res = await this.DoctorRequest.getPublicSessionDetail(mdtSessionId)
      this.mdtCases = res.data.cases
      this.mdtSession = res.data
      this.clinicId = this.$globalClinic?.id
    },
    print () {
      const tempTitle = document.title
      document.title = `Danh sach Benh nhan Mo_${moment().format('DDMMYYYY')}.pdf`
      window.print()
      document.title = tempTitle
    },
    back () {
      const mdtSessionId = this.$route.params.id
      this.$router.push({ name: 'OrganizationSessionDetail', params: { id: mdtSessionId } })
    }
  },
  mounted () {
    this.fetchData()
  }
}
</script>

<style scoped lang="scss">
@font-face {
  font-family: Time;
  src: url(../../../public/assets/font/tnr.ttf);
}
.box-container {
  color: black;
  margin: auto;
  ::v-deep * {
    font-family: Time, serif !important;
  }
}
.box-header {
  margin: 10px 100px;
}
.box-footer {
  display: grid;
  grid-auto-flow: column;
  /*grid-template-rows: repeat(4,minmax(0,1fr));*/
  text-align: center;
}
.font-style {
  color: black;
  font-weight: bold;
  font-size: 13px;
  text-transform: uppercase;
}
table,
th,
td {
  color: black;
  border: 1px solid black;
  border-collapse: collapse;
  text-align: center;
}
table {
  width: 100%;
}
.table-row > td {
  padding: 0 5px;
}
.table-row,
.sign-text {
  font-weight: 500;
  font-size: 13px;
  line-height: 22px;
}
.sign-input {
  outline: 0;
  border-width: 0 0 1px;
  text-align: center;
}
.sign-text {
  display: none;
}
@media print {
  @page {
    size: landscape;
  }
  .container {
    max-width: unset;
  }
  .sign-input {
    display: none;
  }
  .sign-text {
    display: block;
  }
}
</style>